import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { HashLink } from 'react-router-hash-link';

import './index.css';
import { web3 } from '../../store/web3';
import { networkId, message } from '../../store/config';
import { login } from '../../store/actions/Auth';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            address: '',
        };
    };

    componentWillReceiveProps({ publicAddress }) {
        this.setState({ address: publicAddress })
    }

    connectWallet = async () => {
        if (typeof window.ethereum === 'undefined') {
            EventBus.publish('error', `Please Install Metamask!!!`);
            return;
        }

        web3.eth.net.getId(async (err, netId) => {
            if (netId != networkId) {
                EventBus.publish('info', message);
                return;
            }
            let address = (await web3.currentProvider.enable())[0];
            this.props.login(address);
            this.setState({ address });
        });
    };

    render() {
        let { address, isOpen } = this.state;
        let { sticky, publicAddress } = this.props;

        return (
            <div className={`mp-club-nav ${sticky && 'sticky-nav'}`}>
                <nav className='navbar navbar-expand-lg sidenav' id="sidenav-1" data-mdb-hidden="false">
                    <div className='container-fluid'>
                        <div className="inner-container">
                            {/* <Link className='navbar-brand' to='/'><img src={require('../../static/images/landing/logo.png')} alt='' /></Link> */}
                            <button onClick={() => this.setState({ isOpen: true })} className='navbar-toggler' type='button' data-toggle='collapse'
                                data-target='#navbarSupportedContent' aria-controls='navbarSupportedContent' aria-expanded='false'
                                aria-label='Toggle navigation' aria-haspopup="true">
                                <i class='fa fa-bars' aria-hidden='true'></i>
                            </button>
                            <div className='collapse navbar-collapse nav-links' id='navbarSupportedContent'>
                                <ul className='navbar-nav sidenav-menu'>
                                    <li className='nav-item'>
                                        <HashLink className='nav-link' smooth to='/#about'>About</HashLink>
                                    </li>
                                    <li className='nav-item'>
                                        <HashLink className='nav-link' smooth to='/#roadmap'>Roadmap</HashLink>
                                    </li>
                                    <li className='nav-item'>
                                        <HashLink className='nav-link' smooth to='/#nft'>NFT Gallery</HashLink>
                                    </li>

                                    <li className='nav-item'>
                                        <HashLink className='nav-link' smooth to='/#team'>Team</HashLink>
                                    </li>
                                    <li className='nav-item'>
                                        <HashLink className='nav-link' smooth to='/#mint-your-nft-in-three-steps'>How to Mint</HashLink>
                                    </li>
                                    <li className='nav-item'>
                                        <HashLink className='nav-link' smooth to='/#faqs'>FAQs</HashLink>
                                    </li>
                                    <li className='nav-item'>
                                        <a href='https://white-paper-poker-club.s3.us-east-2.amazonaws.com/Whitepaper-MPC.pdf' className='nav-link' target="_blank">Whitepaper</a>
                                    </li>
                                    <button onClick={this.connectWallet} className="nav-btn btn-style-one desktop-btn" type="button">
                                        {publicAddress == '' || publicAddress == null
                                            ? 'Connect wallet'
                                            : publicAddress && publicAddress.substring(0, 5) + '.....' + publicAddress.substring(37, publicAddress.length)
                                        }
                                    </button>

                                    {/* <li className='nav-item nav-img'>
                                        <img className="right-img" src={require('../../static/images/landing/hcl_official_sponsors_logo.png')} alt='' />
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>

            </div >
        );
    }
}

const mapDispatchToProps = {
    login
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress } = Auth;
    return { publicAddress }
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);